export const recipes = [
  {
    id: 1,
    title: "shrimp tacos",
    image: "/recipe_images/shrimp_tacos.jpeg"
  },
  {
    id: 2,
    title: "honey hot salmon burgers",
    image: "/recipe_images/salmon_burgers.jpeg"
  },
  {
    id: 3,
    title: "chicken gyro wrap",
    image: "/recipe_images/chicken_gyro.jpeg"
  },
  {
    id: 4,
    title: "japanese style salmon cabbage & mushroom",
    image: "/recipe_images/salmon_stiry.jpeg"
  },
  {
    id: 5,
    title: "shrimp fried rice",
    image: "/recipe_images/shrimp_rice.jpeg"
  },
  // Add more recipes as needed
]; 